import React from "react";
import { Link } from "gatsby";
import AaFooter from "./AaFooter/AaFooter";
import Navbar from "../components/Navbar/Navbar";
import Environ from "../components/Environ/Environ";
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import { Helmet } from "react-helmet"
import "./all.sass";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showPopup: false, darkMode: true };
  }

  togglePopup() {
    this.setState({ showPopup: !this.state.showPopup });
  }

  render() {
    const { children } = this.props;
    return (
      <ThemeToggler>
        {({ theme, toggleTheme }) => (
          <div id="outer-container">
            <Helmet>
              <title>Aaron Albinson</title>
            </Helmet>

            <div id="page-wrap" className={this.state.showPopup ? "show" : "noshow"}>
              <div class="environ-popup" onClick={() => this.togglePopup()}><Environ /></div>
              <div className="top">
                <div className="top-inner container">
                  <Link to="/">
                    <span className="siteTitle">Aaron Albinson</span>
                    <meta name="description" content="Website developer based in Manchester UK."/>
                      <svg width="60" height="44" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M50.277 37.71H39.36L37.387 44h-6.68l10.977-30.04h6.289L59.047 44H52.27l-1.993-6.29zm-1.425-4.472l-3.223-10.195-.84-3.066-.86 3.066-3.183 10.195h8.106z" fill="#A28845" /><path class="logo-a" d="M.24 44v-4.14c1.12 0 2.02-.32 2.7-.96.68-.64 1.24-1.48 1.68-2.52.48-1.04.94-2.16 1.38-3.36L17.7.92h4.2l12.78 32.7c.24.6.56 1.52.96 2.76.4 1.2.64 2.26.72 3.18.68-.08 1.32-.12 1.92-.12.64-.04 1.16-.06 1.56-.06V44H23.7v-3.96c1.64 0 2.56-.5 2.76-1.5.2-1.04.02-2.3-.54-3.78l-1.2-3.3-13.02.24-.96 2.82-.9 2.34c-.36 1-.7 1.84-1.02 2.52.64-.08 1.4-.12 2.28-.12.92-.04 1.58-.06 1.98-.06V44H.24zM13.2 27.2h10.02l-2.76-7.5c-.52-1.44-.98-2.88-1.38-4.32-.4-1.48-.7-2.78-.9-3.9h-.06l-.72 3.06c-.32 1.36-.82 2.98-1.5 4.86l-2.7 7.8z" fill="#EEE" /></svg>
                  </Link>
                    <Navbar />
                    <div className="right-menu">
                      <span>
                        <label>
                          <input
                            type="checkbox"
                            onChange={e => toggleTheme(e.target.checked ? 'light' : 'dark')}
                            checked={theme === 'light'}
                          />
                          <svg className="mode-toggle light" width="35" height="35" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.458 32.083H16.14C8.097 31.351 2.247 24.17 2.978 16.108 3.563 9.072 9.121 3.503 16.141 2.917c.586 0 1.17.293 1.463.732.293.44.293 1.173-.146 1.613-1.463 1.905-1.902 4.25-1.61 6.595.293 2.345 1.61 4.397 3.511 5.716a8.543 8.543 0 0010.384 0c.439-.293 1.024-.44 1.61-.146.438.293.73.88.73 1.465-.292 3.958-2.193 7.475-5.265 9.967-2.779 2.052-5.996 3.224-9.36 3.224zM13.363 6.288c-4.242 1.465-7.167 5.276-7.606 9.966-.585 6.45 4.095 12.165 10.53 12.751 3.072.294 6.143-.586 8.484-2.638 1.608-1.319 2.778-3.078 3.51-4.983-3.657 1.32-7.752.733-10.97-1.612-2.486-1.906-4.095-4.544-4.68-7.622-.146-2.052.147-3.957.732-5.862z" fill="#F2F2F2" /></svg>
                          <svg className="mode-toggle dark" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.585 26.7707C16.989 26.7707 16.5052 27.2546 16.5052 27.8506V31.0901C16.5052 31.6862 16.989 32.17 17.585 32.17C18.181 32.17 18.6649 31.6862 18.6649 31.0901V27.8506C18.6649 27.2546 18.181 26.7707 17.585 26.7707Z" fill="#F2F2F2" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5052 27.8506C15.5052 26.7023 16.4367 25.7707 17.585 25.7707C18.7333 25.7707 19.6649 26.7023 19.6649 27.8506V31.0901C19.6649 32.2385 18.7333 33.17 17.585 33.17C16.4367 33.17 15.5052 32.2385 15.5052 31.0901V27.8506ZM16.5052 27.8506C16.5052 27.2546 16.989 26.7707 17.585 26.7707C18.181 26.7707 18.6649 27.2546 18.6649 27.8506V31.0901C18.6649 31.6862 18.181 32.17 17.585 32.17C16.989 32.17 16.5052 31.6862 16.5052 31.0901V27.8506Z" fill="#F2F2F2" />
                            <path d="M17.585 3C16.989 3 16.5052 3.48383 16.5052 4.07985V7.3194C16.5052 7.91543 16.989 8.39925 17.585 8.39925C18.181 8.39925 18.6649 7.91543 18.6649 7.3194V4.07985C18.6649 3.48383 18.181 3 17.585 3Z" fill="#F2F2F2" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5052 4.07985C15.5052 2.93155 16.4367 2 17.585 2C18.7333 2 19.6649 2.93155 19.6649 4.07985V7.3194C19.6649 8.46771 18.7333 9.39925 17.585 9.39925C16.4367 9.39925 15.5052 8.46771 15.5052 7.3194V4.07985ZM16.5052 4.07985C16.5052 3.48383 16.989 3 17.585 3C18.181 3 18.6649 3.48383 18.6649 4.07985V7.3194C18.6649 7.91543 18.181 8.39925 17.585 8.39925C16.989 8.39925 16.5052 7.91543 16.5052 7.3194V4.07985Z" fill="#F2F2F2" />
                            <path d="M8.39925 17.585C8.39925 16.9889 7.91543 16.5051 7.3194 16.5051H4.07985C3.48383 16.5051 3 16.9889 3 17.585C3 18.181 3.48383 18.6648 4.07985 18.6648H7.3194C7.91543 18.6648 8.39925 18.181 8.39925 17.585Z" fill="#F2F2F2" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.3194 15.5051C8.46771 15.5051 9.39925 16.4367 9.39925 17.585C9.39925 18.7333 8.46771 19.6648 7.3194 19.6648H4.07985C2.93155 19.6648 2 18.7333 2 17.585C2 16.4367 2.93155 15.5051 4.07985 15.5051H7.3194ZM7.3194 16.5051C7.91543 16.5051 8.39925 16.9889 8.39925 17.585C8.39925 18.181 7.91543 18.6648 7.3194 18.6648H4.07985C3.48383 18.6648 3 18.181 3 17.585C3 16.9889 3.48383 16.5051 4.07985 16.5051H7.3194Z" fill="#F2F2F2" />
                            <path d="M31.0901 16.5051H27.8506C27.2546 16.5051 26.7707 16.9889 26.7707 17.585C26.7707 18.181 27.2546 18.6648 27.8506 18.6648H31.0901C31.6862 18.6648 32.17 18.181 32.17 17.585C32.17 16.9889 31.6862 16.5051 31.0901 16.5051Z" fill="#F2F2F2" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M27.8506 15.5051H31.0901C32.2385 15.5051 33.17 16.4367 33.17 17.585C33.17 18.7333 32.2385 19.6648 31.0901 19.6648H27.8506C26.7023 19.6648 25.7707 18.7333 25.7707 17.585C25.7707 16.4367 26.7023 15.5051 27.8506 15.5051ZM27.8506 16.5051H31.0901C31.6862 16.5051 32.17 16.9889 32.17 17.585C32.17 18.181 31.6862 18.6648 31.0901 18.6648H27.8506C27.2546 18.6648 26.7707 18.181 26.7707 17.585C26.7707 16.9889 27.2546 16.5051 27.8506 16.5051Z" fill="#F2F2F2" />
                            <path d="M10.3276 23.7626C10.0401 23.7626 9.76659 23.8748 9.56324 24.0781L7.27031 26.371C7.06696 26.5744 6.95477 26.8479 6.95477 27.1353C6.95477 27.4228 7.06696 27.6963 7.27031 27.8997C7.47366 28.103 7.74713 28.2152 8.03462 28.2152C8.32212 28.2152 8.59558 28.103 8.79893 27.8997L11.0919 25.6067C11.5126 25.186 11.5126 24.4988 11.0919 24.0781C10.8885 23.8748 10.615 23.7626 10.3276 23.7626Z" fill="#F2F2F2" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.85614 23.371C9.24881 22.9783 9.77738 22.7626 10.3276 22.7626C10.8777 22.7626 11.4063 22.9783 11.799 23.371C12.6102 24.1822 12.6102 25.5026 11.799 26.3138L9.50604 28.6068C9.11337 28.9994 8.5848 29.2152 8.03462 29.2152C7.48445 29.2152 6.95588 28.9994 6.56321 28.6068C6.17054 28.2141 5.95477 27.6855 5.95477 27.1353C5.95477 26.5852 6.17054 26.0566 6.56321 25.6639L8.85614 23.371ZM9.56324 24.0781C9.76659 23.8748 10.0401 23.7626 10.3276 23.7626C10.615 23.7626 10.8885 23.8748 11.0919 24.0781C11.5126 24.4988 11.5126 25.186 11.0919 25.6067L8.79893 27.8997C8.59558 28.103 8.32212 28.2152 8.03462 28.2152C7.74713 28.2152 7.47366 28.103 7.27031 27.8997C7.06696 27.6963 6.95477 27.4228 6.95477 27.1353C6.95477 26.8479 7.06696 26.5744 7.27031 26.371L9.56324 24.0781Z" fill="#F2F2F2" />
                            <path d="M24.8424 11.4074C25.1299 11.4074 25.4034 11.2952 25.6068 11.0919L27.8997 8.79897C28.103 8.59562 28.2152 8.32215 28.2152 8.03466C28.2152 7.74716 28.103 7.4737 27.8997 7.27035C27.6963 7.067 27.4229 6.95481 27.1354 6.95481C26.8479 6.95481 26.5744 7.067 26.3711 7.27035L24.0781 9.56328C23.8748 9.76663 23.7626 10.0401 23.7626 10.3276C23.7626 10.6151 23.8748 10.8885 24.0781 11.0919C24.2815 11.2952 24.555 11.4074 24.8424 11.4074Z" fill="#F2F2F2" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M26.3139 11.799C25.9212 12.1917 25.3926 12.4074 24.8424 12.4074C24.2923 12.4074 23.7637 12.1917 23.371 11.799C22.9784 11.4063 22.7626 10.8778 22.7626 10.3276C22.7626 9.77741 22.9784 9.24884 23.371 8.85617L25.664 6.56324C26.0566 6.17057 26.5852 5.95481 27.1354 5.95481C27.6856 5.95481 28.2141 6.17057 28.6068 6.56324C28.9995 6.95591 29.2152 7.48448 29.2152 8.03466C29.2152 8.58483 28.9995 9.1134 28.6068 9.50607L26.3139 11.799ZM25.6068 11.0919C25.4034 11.2952 25.1299 11.4074 24.8424 11.4074C24.555 11.4074 24.2815 11.2952 24.0781 11.0919C23.8748 10.8885 23.7626 10.6151 23.7626 10.3276C23.7626 10.0401 23.8748 9.76663 24.0781 9.56328L26.3711 7.27035C26.5744 7.067 26.8479 6.95481 27.1354 6.95481C27.4229 6.95481 27.6963 7.067 27.8997 7.27035C28.103 7.4737 28.2152 7.74716 28.2152 8.03466C28.2152 8.32215 28.103 8.59562 27.8997 8.79897L25.6068 11.0919Z" fill="#F2F2F2" />
                            <path d="M8.79893 7.27035C8.59558 7.067 8.32212 6.95481 8.03462 6.95481C7.74713 6.95481 7.47366 7.067 7.27031 7.27035C7.06696 7.4737 6.95477 7.74716 6.95477 8.03466C6.95477 8.32215 7.06696 8.59562 7.27031 8.79897L9.56324 11.0919C9.76659 11.2952 10.0401 11.4074 10.3276 11.4074C10.615 11.4074 10.8885 11.2952 11.0919 11.0919C11.2952 10.8885 11.4074 10.6151 11.4074 10.3276C11.4074 10.0401 11.2952 9.76663 11.0919 9.56328L8.79893 7.27035Z" fill="#F2F2F2" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.03462 5.95481C8.5848 5.95481 9.11337 6.17057 9.50604 6.56324L11.799 8.85617C12.1916 9.24884 12.4074 9.77741 12.4074 10.3276C12.4074 10.8778 12.1916 11.4063 11.799 11.799C11.4063 12.1917 10.8777 12.4074 10.3276 12.4074C9.77738 12.4074 9.24881 12.1917 8.85614 11.799L6.56321 9.50607C6.17054 9.1134 5.95477 8.58483 5.95477 8.03466C5.95477 7.48448 6.17054 6.95591 6.56321 6.56324C6.95588 6.17057 7.48445 5.95481 8.03462 5.95481ZM8.03462 6.95481C8.32212 6.95481 8.59558 7.067 8.79893 7.27035L11.0919 9.56328C11.2952 9.76663 11.4074 10.0401 11.4074 10.3276C11.4074 10.6151 11.2952 10.8885 11.0919 11.0919C10.8885 11.2952 10.615 11.4074 10.3276 11.4074C10.0401 11.4074 9.76659 11.2952 9.56324 11.0919L7.27031 8.79897C7.06696 8.59562 6.95477 8.32215 6.95477 8.03466C6.95477 7.74716 7.06696 7.4737 7.27031 7.27035C7.47366 7.067 7.74713 6.95481 8.03462 6.95481Z" fill="#F2F2F2" />
                            <path d="M25.6068 24.0781C25.4034 23.8748 25.1299 23.7626 24.8424 23.7626C24.555 23.7626 24.2815 23.8748 24.0781 24.0781C23.6574 24.4988 23.6574 25.186 24.0781 25.6067L26.3711 27.8997C26.5744 28.103 26.8479 28.2152 27.1354 28.2152C27.4229 28.2152 27.6963 28.103 27.8997 27.8997C28.103 27.6963 28.2152 27.4228 28.2152 27.1353C28.2152 26.8479 28.103 26.5744 27.8997 26.371L25.6068 24.0781Z" fill="#F2F2F2" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M25.664 28.6068L23.371 26.3138C22.5598 25.5026 22.5598 24.1822 23.371 23.371C23.7637 22.9783 24.2923 22.7626 24.8424 22.7626C25.3926 22.7626 25.9212 22.9783 26.3139 23.371L28.6068 25.6639C28.9995 26.0566 29.2152 26.5852 29.2152 27.1353C29.2152 27.6855 28.9995 28.2141 28.6068 28.6068C28.2141 28.9994 27.6856 29.2152 27.1354 29.2152C26.5852 29.2152 26.0566 28.9994 25.664 28.6068ZM24.0781 25.6067L26.3711 27.8997C26.5744 28.103 26.8479 28.2152 27.1354 28.2152C27.4229 28.2152 27.6963 28.103 27.8997 27.8997C28.103 27.6963 28.2152 27.4228 28.2152 27.1353C28.2152 26.8479 28.103 26.5744 27.8997 26.371L25.6068 24.0781C25.4034 23.8748 25.1299 23.7626 24.8424 23.7626C24.555 23.7626 24.2815 23.8748 24.0781 24.0781C23.6574 24.4988 23.6574 25.186 24.0781 25.6067Z" fill="#F2F2F2" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.85346 17.585C7.85346 12.2188 12.2188 7.85346 17.585 7.85346C22.9512 7.85346 27.3165 12.2188 27.3165 17.585C27.3165 22.9512 22.9512 27.3165 17.585 27.3165C12.2188 27.3165 7.85346 22.9512 7.85346 17.585ZM10.8535 17.585C10.8535 13.8756 13.8756 10.8535 17.585 10.8535C21.2944 10.8535 24.3165 13.8756 24.3165 17.585C24.3165 21.2944 21.2944 24.3165 17.585 24.3165C13.8756 24.3165 10.8535 21.2944 10.8535 17.585Z" fill="#F2F2F2" />
                          </svg>
                        </label>
                      </span>
                      <span onClick={() => this.togglePopup()}>
                        <svg className="environ-popup-toggle" width="35" height="35" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.467 0c-3.08.004-6.104.813-8.772 2.349a17.582 17.582 0 00-6.43 6.401h4.663v3.507H.722A17.471 17.471 0 000 15.763h5.187v3.507H0c.112 1.245.354 2.475.722 3.67h6.206v3.277H2.265a17.515 17.515 0 006.408 6.422 17.558 17.558 0 0017.54.028 17.516 17.516 0 006.429-6.401 17.476 17.476 0 00.013-17.509 17.515 17.515 0 00-6.418-6.41A17.557 17.557 0 0017.467 0zm4.366 17.5a4.403 4.403 0 01-2.862-1.407 4.387 4.387 0 010-5.936 4.403 4.403 0 017.642 2.968 4.386 4.386 0 01-2.983 4.157 4.408 4.408 0 01-1.797.218z" fill="#EEE" /></svg>
                      </span>
                    </div>
                </div>
                </div>
                {children}
              </div>
              <AaFooter />
            </div>
        )}
      </ThemeToggler>
        )
        }
}

        export default Layout;
